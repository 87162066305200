<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile-nologo'">
    <v-container class="complete-container" fill-height>
      <v-card outlined max-width="650px" width="100%" :class="'complete-card mb-5 ' + (mobile ? '' : 'mt-10')">
        <v-row class="ma-0 pa-0">
          <v-col class="pa-8">
            <div class="title-text text-center">¡Gracias!</div>
            <v-row justify="center">
              <v-img
                :src="require('@/assets/img/thanks.svg')"
                class="rounded mb-8"
                contain
                :max-width="desktop ? '50%' : '60%'"
              />
            </v-row>
            <div class="body-text">
              Completaste la evaluación y no podemos agradecerte lo suficiente por ello. Esta información es muy valiosa
              para nosotros, ya que permitirá mejorar la conexión entre empresas relacionadas a la Transformación
              Digital.
            </div>
            <footer style="padding-top: 30px; font-size: 13px; line-height: 20px; text-align: center">
              <div style="color: #7319d5">
                <b> Tway </b>
              </div>
              <div style="color: #7319d5">Transforming Business Together</div>
              <p><b> Conócenos. Contáctanos. </b></p>
              <p>
                <a href="mailto:contacto@tway.cl" style="text-decoration: none">
                  <img :src="require('@/assets/img/mail.png')" height="15px" style="height: 15px" />
                </a>
                <a href="https://www.linkedin.com/company/tway" style="text-decoration: none">
                  <img :src="require('@/assets/img/linkedin.png')" height="15px" style="height: 15px" />
                </a>
              </p>
              <p>
                <a href="https://www.tway.cl" style="color: #7319d5; text-decoration: none">
                  <b> www.tway.cl </b>
                </a>
              </p>
            </footer>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import ImpactsJSON from "@/utils/impacts.json";
import portfolioService from "@/services/portfolioService";
export default {
  name: "SuccesfulEvaluation",
  data() {
    return {
      valid: true,
      token: null,
      password: "",
      passwordConfirm: "",
      showPassword: false,
      editToken: "",
      breafcaseId: "",
      clientRate: 0,
      impacts: [],
      portfolio: {
        impacts: [],
      },
    };
  },

  created: function () {
    this.editToken = this.$route.params.id;
    this.getImpacts();
  },
  methods: {
    getImpacts() {
      this.impacts = ImpactsJSON;
    },
    updateportfolio: function (breafcaseId) {
      let data = {
        clientRate: this.clientRate,
      };
      portfolioService
        .updateportfolio(data, breafcaseId)
        .then()
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
}
.body-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}
.profile-content-box {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
.small-checkbox {
  margin-top: 0px;
  padding-top: 0px;
}

.small-checkbox > div > div {
  align-items: start;
}
.complete-container {
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 1265px) {
    flex-direction: row;
  }
  @media only screen and (max-width: 1264px) {
    flex-direction: column;
  }
}
.black-text {
  color: #333333;
}
</style>
